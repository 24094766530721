<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">商家管理</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <div
          v-show="!grid.isEditing && grid.isRowSelected"
          id="batch-dropdown"
          class="dropdown ml-auto mr-2 sm:ml-0"
        >
          <button
            class="dropdown-toggle button px-2 box bg-blue-500 text-white flex items-center"
          >
            <FontAwesome icon="tasks" type="fas" class="w-4 h-4 mr-1" />
            群組操作
          </button>
          <div class="dropdown-box">
            <div class="dropdown-box__content box dark:bg-dark-1 p-2 w-32">
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.removeSelectedRows"
              >
                <FontAwesome icon="trash" type="fas" class="w-4 h-4 mr-2" /> 刪除
              </span> -->
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onPrint({ mode: 'selected' })"
              >
                <FontAwesome icon="print" type="fas" class="w-4 h-4 mr-2" /> 列印
              </span> -->
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'csv', mode: 'selected' })"
              >
                <FontAwesome icon="file-csv" type="fas" class="w-4 h-4 mr-2" />
                匯出 CSV
              </span>
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'html', mode: 'selected' })"
              >
                <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> 匯出 HTML
              </span> -->
            </div>
          </div>
        </div>
        <button
          v-show="!grid.isEditing"
          class="button text-white bg-theme-1 shadow-md mr-2 flex items-center"
          @click="grid.addNewRow"
        >
          <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
          新增
        </button>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <input
              type="text"
              class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border"
              v-model.trim="grid.keyword"
              placeholder="請輸入關鍵字"
              @keyup.enter="grid.refresh"
            />
          </div>
          <div class="mt-2 xl:mt-0">
            <button
              type="button"
              class="button w-full sm:w-16 bg-theme-1 text-white"
              @click="grid.refresh"
            >
              搜尋
            </button>
          </div>
        </div>
        <div class="flex mt-2 sm:mt-0">
          <!-- <button
            class="button w-1/2 sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            @click="grid.onPrint({ mode: 'curret' })"
          >
            <FontAwesome icon="print" class="w-4 h-4 mr-2" /> 列印
          </button> -->
          <div class="dropdown w-1/2 sm:w-auto ml-2">
            <button
              class="dropdown-toggle button w-full sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            >
              <FontAwesome icon="file-export" class="w-4 h-4 mr-2" /> 匯出
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button>
            <div class="dropdown-box">
              <div class="dropdown-box__content box dark:bg-dark-1 p-2">
                <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'csv' })"
                >
                  <FontAwesome icon="file-csv" class="w-4 h-4 mr-2" /> CSV
                </a>
                <!-- <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'html' })"
                >
                  <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> HTML
                </a> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <grid
          ref="grid"
          v-bind="gridOptions"
          @addNewRow="(row, callback) => { row.Status = 0; row.IsIndependent = false; row.TaxType = 2; row.PaymentType = 3; callback(); }"
          @edit="(row, callback) => { row.Password = undefined; callback(); }"
          @reset="reset"
          @removeSelectedRows="hideBatchDropDown()"
        >
          <template #modal="{ row, submit, reset }">
            <vxe-form
              ref="form"
              v-bind="formOptions"
              :data="row"
              @reset="reset"
              @submit="submit"
            >
              <template #column-principal-id="{ data }">
                <select-box v-bind="selectorOptions" v-model="data.PrincipalId" />
              </template>
            </vxe-form>
          </template>
          <template #modal-footer>
            <vxe-button type="submit" status="primary" content="確認" @click="$refs.form.validate((errMap) => { if (errMap === undefined) $refs.form.dispatchEvent('submit'); })"></vxe-button>
            <vxe-button type="reset" content="重置" @click="$refs.form.dispatchEvent('reset')"></vxe-button>
          </template>
          <template #filter-name="{ column, $panel }">
            <div
              v-for="(option, index) in column.filters"
              :key="index"
              class="flex item-center justify-center"
            >
              <input
                type="checkbox"
                class="input input--switch border mt-4 ml-2"
                :checked="option.checked"
                @input="grid.changeFilterEvent(event, option, $panel, true)"
              />
              <div class="relative m-2">
                <div class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600">
                  {{ option.label }}
                </div>
                <input
                  v-model.trim="option.data"
                  :disabled="!option.checked"
                  type="text"
                  class="input pl-12 w-full border col-span-4"
                  placeholder="請輸入文字"
                  @input="grid.changeFilterEvent(event, option, $panel)"
                />
              </div>
            </div>
          </template>
        </grid>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script lang="ts">
import CloudFun, { defineComponent, ref } from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps } from "vxe-table";
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import { VueUploadItem } from "vue-upload-component";

export default defineComponent({
  components: {
    Grid,
    SelectBox,
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});
    const uploader = ref<any>({});

    var printColumns = [{ field: "Name" }, { field: "Description" }];

    const gridOptions: GridOptions = {
      id: 'merchant',
      title: '商家',
      toolbarConfig: {
        custom: true,
        refresh: true,
      },
      printConfig: {
        sheetName: "商家清單",
        columns: printColumns,
        modes: ["current", "selected", "all"],
      },
      exportConfig: {
        filename: "商家清單",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        columns: printColumns,
      },
      columns: [
        { field: "Number", title: "編號", showHeaderOverflow: true, showOverflow: true, resizable: false },
        {
          field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true,
          filters: [{ checked: false, label: '包含' }],
          slots: { filter: 'filter-name' }
        },
        { field: "IsIndependent", title: "獨立設計師", showHeaderOverflow: true, showOverflow: true, resizable: false, formatter: ({ cellValue }) => cellValue ? "是" : "否" },
        { field: "Principal.Name", title: "負責人", showHeaderOverflow: true, showOverflow: true, resizable: false },
        { field: "Phone", title: "電話號碼", showHeaderOverflow: true, showOverflow: true, resizable: false },
        { field: "Email", title: "Email", showHeaderOverflow: true, showOverflow: true, resizable: false },
        { field: "Status", title: "狀態", showHeaderOverflow: true, showOverflow: true, resizable: false, formatter: ({ cellValue }) => model ? Object.values(model.enums.UserStatus).find(e => e.Value === cellValue)?.Name : undefined },
      ],
      promises: {
        query: model ? (params) => model.dispatch("merchant/query", params) : undefined,
        queryAll: model ? () => model.dispatch("merchant/query") : undefined,
        save: model ? (params) => model.dispatch("merchant/save", params) : undefined,
      },
      modalConfig: { width: 650, showFooter: true },
    };

    const formOptions: VxeFormProps = {
      titleWidth: 100,
      titleAlign: "right",
      span: 3,
      items: [
        { field: 'Number', title: '編號', span: 12, itemRender: { name: '$input', props: { placeholder: '系統自動產生', disabled: true } } },
        { field: 'Status', title: '狀態', span: 12, itemRender: { name: '$select', options: model ? Object.values(model.enums.UserStatus).map(e => { return { label: e.Name, value: e.Value } }) : [] } },
        { field: 'Name', title: '名稱', span: 12, itemRender: { name: '$input', props: { placeholder: '請輸入文字' } } },
        { field: 'IsIndependent', title: '獨立設計師', span: 12, itemRender: { name: '$select', props: { placeholder: '請選擇' }, options: [{ label: '是', value: true }, { label: '否', value: false }] } },
        { field: 'PrincipalId', title: '負責人', span: 12, slots: { default: 'column-principal-id' } },
        { field: 'TaxType', title: '稅別', span: 12, itemRender: { name: '$select', options: model ? Object.values(model.enums.TaxType).map(e => { return { label: e.Name, value: e.Value } }) : [] } },
        { field: 'SettleDay', title: '結算日', span: 12, itemRender: { name: '$input', props: { type: 'number', min: 1, max: 28, placeholder: '請輸入1~28的數字' } } },
        { field: 'PaymentType', title: '付款方式', span: 12, itemRender: { name: '$select', options: model ? Object.values(model.enums.PaymentType).map(e => { return { label: e.Name, value: e.Value } }) : [] } },
        { field: 'InvoiceTitle', title: '發票抬頭', span: 12, itemRender: { name: '$input', props: { placeholder: '請輸入統編' } } },
        { field: 'TaxId', title: '統一編號', span: 12, itemRender: { name: '$input', props: { placeholder: '請輸入文字' } } },
        { field: 'InvoiceAddress.Line', title: '發票地址', span: 24, itemRender: { name: '$input', props: { placeholder: '請輸入地址' } } },
        { field: 'Phone', title: '電話號碼', span: 12, itemRender: { name: '$input', props: { placeholder: '請輸入電話號碼' } } },
        { field: 'Fax', title: '傳真號碼', span: 12, itemRender: { name: '$input', props: { placeholder: '請輸入電話號碼' } } },
        { field: 'Email', title: 'Email', span: 24, itemRender: { name: '$input', props: { placeholder: '請輸入Email' } } },
        { field: 'Address.Line', title: '地址', span: 24, itemRender: { name: '$input', props: { placeholder: '請輸入地址' } } },
      ],
      rules: {
        Name: [{ required: true }],
        PrincipalId: [{ required: true }],
        SettleDay: [{ required: true }],
        InvoiceTitle: [{ required: true }],
        TaxId: [{
          required: true,
          validator: (params) => {
            if (!params.itemValue) return new Error();
            if (params.itemValue && !new RegExp("^[0-9]{8}$").test(params.itemValue)) return new Error('統一編號格式錯誤');
          }
        }],
        Phone: [{
          validator: (params) => {
            if (!params.itemValue) return;
            return CloudFun.utils.validator.validatePhoneNumber(params.itemValue, 'TW');
          }
        }],
        Fax: [{
          validator: (params) => {
            if (!params.itemValue) return;
            return CloudFun.utils.validator.validatePhoneNumber(params.itemValue, 'TW');
          }
        }],
        Email: [{
          required: true,
          validator: (params) => {
            if (!params.itemValue) return new Error('Email為必填');
            return CloudFun.utils.validator.validateEmail(params.itemValue);
          }
        }],
      },
    };

    const selectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇人物",
      textField: "Name",
      valueField: "Id",
      dropdownWidth: 450,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: "MobilePhone", title: "行動電話", showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: "Email", title: "信箱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: false,
      showHeader: true,
      promises: {
        find: (value) => model!.dispatch("person/find", value), // eslint-disable-line
        query: (params) => model!.dispatch("person/query", params), // eslint-disable-line
      },
    };

    return {
      grid,
      uploader,
      gridOptions,
      formOptions,
      selectorOptions,
      uploadAction: `${process.env.VUE_APP_STORAGE_SERVICE}/api/files`,
    };
  },
  methods: {
    hideBatchDropDown() {
      cash("#batch-dropdown").dropdown("hide");
    },
    async uploaderFilter(current: VueUploadItem, original: VueUploadItem, prevent: any) {
      if (!current || !current.name) return prevent();
      if (!/\.(png|gif|jpg|jpeg)$/i.test(current.name)) {
        alert("未支援此種圖片格式");
        return prevent();
      }
    },
    reset(row: any, callback: any) {
      callback();
      if (!row.Id) {
        row.Status = 0;
        row.IsIndependent = false;
        row.TaxType = 2;
        row.PaymentType = 3;
      }
    }
  },
});
</script>
