
import CloudFun, { defineComponent, ref } from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps } from "vxe-table";
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import { VueUploadItem } from "vue-upload-component";

export default defineComponent({
  components: {
    Grid,
    SelectBox,
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});
    const uploader = ref<any>({});

    var printColumns = [{ field: "Name" }, { field: "Description" }];

    const gridOptions: GridOptions = {
      id: 'merchant',
      title: '商家',
      toolbarConfig: {
        custom: true,
        refresh: true,
      },
      printConfig: {
        sheetName: "商家清單",
        columns: printColumns,
        modes: ["current", "selected", "all"],
      },
      exportConfig: {
        filename: "商家清單",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        columns: printColumns,
      },
      columns: [
        { field: "Number", title: "編號", showHeaderOverflow: true, showOverflow: true, resizable: false },
        {
          field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true,
          filters: [{ checked: false, label: '包含' }],
          slots: { filter: 'filter-name' }
        },
        { field: "IsIndependent", title: "獨立設計師", showHeaderOverflow: true, showOverflow: true, resizable: false, formatter: ({ cellValue }) => cellValue ? "是" : "否" },
        { field: "Principal.Name", title: "負責人", showHeaderOverflow: true, showOverflow: true, resizable: false },
        { field: "Phone", title: "電話號碼", showHeaderOverflow: true, showOverflow: true, resizable: false },
        { field: "Email", title: "Email", showHeaderOverflow: true, showOverflow: true, resizable: false },
        { field: "Status", title: "狀態", showHeaderOverflow: true, showOverflow: true, resizable: false, formatter: ({ cellValue }) => model ? Object.values(model.enums.UserStatus).find(e => e.Value === cellValue)?.Name : undefined },
      ],
      promises: {
        query: model ? (params) => model.dispatch("merchant/query", params) : undefined,
        queryAll: model ? () => model.dispatch("merchant/query") : undefined,
        save: model ? (params) => model.dispatch("merchant/save", params) : undefined,
      },
      modalConfig: { width: 650, showFooter: true },
    };

    const formOptions: VxeFormProps = {
      titleWidth: 100,
      titleAlign: "right",
      span: 3,
      items: [
        { field: 'Number', title: '編號', span: 12, itemRender: { name: '$input', props: { placeholder: '系統自動產生', disabled: true } } },
        { field: 'Status', title: '狀態', span: 12, itemRender: { name: '$select', options: model ? Object.values(model.enums.UserStatus).map(e => { return { label: e.Name, value: e.Value } }) : [] } },
        { field: 'Name', title: '名稱', span: 12, itemRender: { name: '$input', props: { placeholder: '請輸入文字' } } },
        { field: 'IsIndependent', title: '獨立設計師', span: 12, itemRender: { name: '$select', props: { placeholder: '請選擇' }, options: [{ label: '是', value: true }, { label: '否', value: false }] } },
        { field: 'PrincipalId', title: '負責人', span: 12, slots: { default: 'column-principal-id' } },
        { field: 'TaxType', title: '稅別', span: 12, itemRender: { name: '$select', options: model ? Object.values(model.enums.TaxType).map(e => { return { label: e.Name, value: e.Value } }) : [] } },
        { field: 'SettleDay', title: '結算日', span: 12, itemRender: { name: '$input', props: { type: 'number', min: 1, max: 28, placeholder: '請輸入1~28的數字' } } },
        { field: 'PaymentType', title: '付款方式', span: 12, itemRender: { name: '$select', options: model ? Object.values(model.enums.PaymentType).map(e => { return { label: e.Name, value: e.Value } }) : [] } },
        { field: 'InvoiceTitle', title: '發票抬頭', span: 12, itemRender: { name: '$input', props: { placeholder: '請輸入統編' } } },
        { field: 'TaxId', title: '統一編號', span: 12, itemRender: { name: '$input', props: { placeholder: '請輸入文字' } } },
        { field: 'InvoiceAddress.Line', title: '發票地址', span: 24, itemRender: { name: '$input', props: { placeholder: '請輸入地址' } } },
        { field: 'Phone', title: '電話號碼', span: 12, itemRender: { name: '$input', props: { placeholder: '請輸入電話號碼' } } },
        { field: 'Fax', title: '傳真號碼', span: 12, itemRender: { name: '$input', props: { placeholder: '請輸入電話號碼' } } },
        { field: 'Email', title: 'Email', span: 24, itemRender: { name: '$input', props: { placeholder: '請輸入Email' } } },
        { field: 'Address.Line', title: '地址', span: 24, itemRender: { name: '$input', props: { placeholder: '請輸入地址' } } },
      ],
      rules: {
        Name: [{ required: true }],
        PrincipalId: [{ required: true }],
        SettleDay: [{ required: true }],
        InvoiceTitle: [{ required: true }],
        TaxId: [{
          required: true,
          validator: (params) => {
            if (!params.itemValue) return new Error();
            if (params.itemValue && !new RegExp("^[0-9]{8}$").test(params.itemValue)) return new Error('統一編號格式錯誤');
          }
        }],
        Phone: [{
          validator: (params) => {
            if (!params.itemValue) return;
            return CloudFun.utils.validator.validatePhoneNumber(params.itemValue, 'TW');
          }
        }],
        Fax: [{
          validator: (params) => {
            if (!params.itemValue) return;
            return CloudFun.utils.validator.validatePhoneNumber(params.itemValue, 'TW');
          }
        }],
        Email: [{
          required: true,
          validator: (params) => {
            if (!params.itemValue) return new Error('Email為必填');
            return CloudFun.utils.validator.validateEmail(params.itemValue);
          }
        }],
      },
    };

    const selectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇人物",
      textField: "Name",
      valueField: "Id",
      dropdownWidth: 450,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: "MobilePhone", title: "行動電話", showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: "Email", title: "信箱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: false,
      showHeader: true,
      promises: {
        find: (value) => model!.dispatch("person/find", value), // eslint-disable-line
        query: (params) => model!.dispatch("person/query", params), // eslint-disable-line
      },
    };

    return {
      grid,
      uploader,
      gridOptions,
      formOptions,
      selectorOptions,
      uploadAction: `${process.env.VUE_APP_STORAGE_SERVICE}/api/files`,
    };
  },
  methods: {
    hideBatchDropDown() {
      cash("#batch-dropdown").dropdown("hide");
    },
    async uploaderFilter(current: VueUploadItem, original: VueUploadItem, prevent: any) {
      if (!current || !current.name) return prevent();
      if (!/\.(png|gif|jpg|jpeg)$/i.test(current.name)) {
        alert("未支援此種圖片格式");
        return prevent();
      }
    },
    reset(row: any, callback: any) {
      callback();
      if (!row.Id) {
        row.Status = 0;
        row.IsIndependent = false;
        row.TaxType = 2;
        row.PaymentType = 3;
      }
    }
  },
});
